/* globals.css or any CSS file that's imported globally */
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.revolving-text {
  display: inline-block;
  white-space: nowrap;
  font-family: 'Akira Expanded', sans-serif;
  color: #fff3e5;
  font-size: 80px;
}

.revolving-bar-bg {
  background: linear-gradient(
    90deg,
    rgba(237, 225, 102, 1) 0%,
    rgba(248, 168, 69, 1) 22%,
    rgba(255, 160, 157, 1) 62%,
    rgba(240, 223, 151, 1) 96%
  );
  height: 115px;
  display: flex;
  align-items: center;
}
