/* 4385 pxh 2462pxx */
/* @import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black:wght@200;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.cdnfonts.com/css/akira-expanded"); */


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* html {
    font-family: "Archivo", sans-serif;
  } */
  h1 {
	@apply font-akira_expanded;
	@apply font-extrabold;
	@apply text-[18px];
	@apply md:text-[32px];
  }
  h1.artistprofile {
    @apply font-bayside_demo;
    @apply text-[52px];
	@apply font-normal;
    /* @apply md:text-[32px]; */
    @apply tracking-wide;
  }
  h1.landing {
	@apply font-akira_expanded;
    @apply font-extrabold;
	@apply text-[35px];
	@apply xl:text-[77px];
  }

  h2 {
	@apply font-archivo_black;
	@apply text-[19px];
	@apply md:text-[39px];
  }
  h2.landing {
	@apply font-archivo_black;
	@apply text-[30px];
	@apply xl:text-[55px];
  }

  h3 {
	@apply font-archivo_black;
	@apply text-[18px];
	@apply md:text-[20px];
  }
  h3.landing {
	@apply font-archivo;
	@apply font-semibold;
	@apply text-[24px];
	@apply xl:text-[39px];
  }
  
  h4 {
	@apply font-bebas_neue;
	@apply text-[16px];
	@apply md:text-[24px];
  }
  h4.landing {
	@apply font-archivo_black;
	@apply text-[19px];
	@apply xl:text-[35px];
  }

  p {
	@apply font-archivo;
    @apply font-normal;
	@apply text-[13px];
	@apply md:text-[16px];
  }
}
:root {
	--backgroundImage: url('/path/to/default/background/image.jpg');
  }
  
  /* Usage */
  .someElement {
	background-image: var(--backgroundImage);
  }
  .bg-contain {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
  }
  .bg-stretch {
	background-size: 100% 100%;
	background-repeat: no-repeat;
  }
  
@layer components {
	.debug {
		@apply border border-red-500;
	}

	.horizontal {
		@apply flex flex-row;
	}

	.vertical {
		@apply flex flex-col;
	}

	.horizontal.center-v {
		@apply items-center;
	}

	.horizontal.center-h {
		@apply justify-center;
	}

	.horizontal.center {
		@apply justify-center items-center;
	}

	.vertical.center-v {
		@apply justify-center;
	}

	.vertical.center-h {
		@apply items-center;
	}

	.vertical.center {
		@apply justify-center items-center;
	}

	.space-between {
		@apply justify-between;
	}
}

/* .bg {
	background-color: #191919;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
  }
  
  .bg .div {
	background-color: #191919;
	height: 4351px;
	position: relative;
	width: 2560px;
  }
  
  .bg .overlap {
	height: 239px;
	left: 326px;
	position: absolute;
	top: 16px;
	width: 304px;
  }
  
  .bg .DIE-HARD {
	color: #cee74b;
	font-family: "Archivo-ExtraBold", Helvetica;
	font-size: 55px;
	font-weight: 800;
	left: 0;
	letter-spacing: 0;
	line-height: 45px;
	position: absolute;
	top: 0;
	width: 304px;
  }
  
  .bg .text-wrapper {
	color: #cee74b;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 25px;
	font-weight: 500;
	left: 0;
	letter-spacing: 0;
	line-height: 29px;
	position: absolute;
	top: 95px;
	white-space: nowrap;
	width: 167px;
  }
  
  .bg .line {
	height: 10px;
	left: 109px;
	position: absolute;
	top: 18px;
	width: 58px;
  }
  
  .bg .overlap-group {
	height: 1413px;
	left: 630px;
	position: absolute;
	top: 150px;
	width: 1311px;
  }
  
  .bg .frame {
	height: 1221px;
	left: 0;
	position: absolute;
	top: 0;
	width: 1311px;
  }
  
  .bg .group {
	height: 77px;
	left: 38px;
	position: absolute;
	top: 770px;
	width: 203px;
  }
  
  .bg .div-wrapper {
	border: 3px solid;
	border-color: #f75918;
	border-radius: 10px;
	height: 77px;
	position: relative;
	width: 201px;
  }
  
  .bg .text-wrapper-2 {
	color: #f75918;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 25px;
	font-weight: 500;
	left: 56px;
	letter-spacing: 0;
	line-height: 55px;
	position: absolute;
	top: 10px;
	white-space: nowrap;
	width: 84px;
  }
  
  .bg .overlap-2 {
	height: 1195px;
	left: 0;
	position: absolute;
	top: 26px;
	width: 1311px;
  }
  
  .bg .overlap-3 {
	height: 677px;
	left: 0;
	position: absolute;
	top: 0;
	width: 1241px;
  }
  
  .bg .overlap-4 {
	height: 358px;
	left: 0;
	position: absolute;
	top: 0;
	width: 1241px;
  }
  
  .bg .rectangle {
	background-color: #f75918;
	border-radius: 15px;
	height: 86px;
	left: 0;
	position: absolute;
	top: 0;
	width: 255px;
  }
  
  .bg .rectangle-2 {
	background-color: #f75918;
	border-radius: 15px;
	height: 86px;
	left: 520px;
	position: absolute;
	top: 4px;
	width: 246px;
  }
  
  .bg .FOR-THE-ARTISTS {
	color: #ffffff;
	font-family: "Space Grotesk-Bold", Helvetica;
	font-size: 55px;
	font-weight: 700;
	left: 7px;
	letter-spacing: 0;
	line-height: 29px;
	position: absolute;
	top: 33px;
	white-space: nowrap;
	width: 1146px;
  }
  
  .bg .ellipse {
	height: 269px;
	left: 891px;
	position: absolute;
	top: 89px;
	width: 350px;
  }
  
  .bg .img {
	height: 269px;
	left: 861px;
	position: absolute;
	top: 54px;
	width: 350px;
  }
  
  .bg .ellipse-2 {
	height: 317px;
	left: 863px;
	position: absolute;
	top: 17px;
	width: 308px;
  }
  
  .bg .ellipse-3 {
	height: 545px;
	left: 29px;
	position: absolute;
	top: 132px;
	width: 545px;
  }
  
  .bg .line-2 {
	height: 5px;
	left: 236px;
	position: absolute;
	top: 59px;
	width: 270px;
  }
  
  .bg .overlap-5 {
	height: 750px;
	left: 671px;
	position: absolute;
	top: 445px;
	width: 640px;
  }
  
  .bg .you-can-engage-with {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 18px;
	font-weight: 500;
	left: 0;
	letter-spacing: 0;
	line-height: 23px;
	position: absolute;
	top: 141px;
	width: 640px;
  }
  
  .bg .p {
	color: #f75918;
	font-family: "Archivo-Bold", Helvetica;
	font-size: 55px;
	font-weight: 700;
	left: 0;
	letter-spacing: 0;
	line-height: 55px;
	position: absolute;
	top: 0;
	width: 640px;
  }
  
  .bg .ellipse-4 {
	height: 464px;
	left: 144px;
	position: absolute;
	top: 286px;
	width: 463px;
  }
  
  .bg .overlap-wrapper {
	background-color: #f75918;
	border-radius: 10px;
	height: 77px;
	left: 272px;
	position: absolute;
	top: 770px;
	width: 304px;
  }
  
  .bg .overlap-6 {
	background-color: #f75918;
	border-radius: 10px;
	height: 77px;
	position: relative;
	width: 302px;
  }
  
  .bg .text-wrapper-3 {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 25px;
	font-weight: 500;
	left: 58px;
	letter-spacing: 0;
	line-height: 55px;
	position: absolute;
	top: 13px;
	white-space: nowrap;
	width: 185px;
  }
  
  .bg .line-3 {
	height: 10px;
	left: 464px;
	position: absolute;
	top: 998px;
	width: 292px;
  }
  
  .bg .ENGAGE-WITH-YOUR-DIE {
	color: #ffffff;
	font-family: "Archivo-ExtraBold", Helvetica;
	font-size: 85px;
	font-weight: 800;
	left: 18px;
	letter-spacing: 0;
	line-height: 85px;
	position: absolute;
	top: 963px;
	width: 927px;
  }
  
  .bg .musicians-can-engage {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 35px;
	font-weight: 500;
	left: 24px;
	letter-spacing: 0;
	line-height: 40px;
	position: absolute;
	top: 1277px;
	width: 850px;
  }
  
  .bg .overlap-7 {
	height: 750px;
	left: 648px;
	position: absolute;
	top: 1719px;
	width: 1329px;
  }
  
  .bg .overlap-8 {
	height: 738px;
	left: 0;
	position: absolute;
	top: 0;
	width: 1329px;
  }
  
  .bg .line-4 {
	height: 10px;
	left: 665px;
	position: absolute;
	top: 63px;
	width: 292px;
  }
  
  .bg .LEARN-MORE-ABOUT {
	color: #ffffff;
	font-family: "Archivo-ExtraBold", Helvetica;
	font-size: 82px;
	font-weight: 800;
	left: 405px;
	letter-spacing: 0;
	line-height: 85px;
	position: absolute;
	text-align: right;
	top: 25px;
	width: 928px;
  }
  
  .bg .rectangle-3 {
	background-color: #ffbed6;
	border-radius: 15px;
	height: 526px;
	left: 178px;
	position: absolute;
	top: 0;
	width: 370px;
  }
  
  .bg .rectangle-4 {
	height: 509px;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 32px;
	width: 506px;
  }
  
  .bg .ellipse-5 {
	border: 5px solid;
	border-color: #ffffff;
	border-radius: 158.5px;
	height: 317px;
	left: 939px;
	position: absolute;
	top: 421px;
	width: 317px;
  }
  
  .bg .ellipse-6 {
	border: 5px solid;
	border-color: #ffffff;
	border-radius: 158.5px;
	height: 317px;
	left: 975px;
	position: absolute;
	top: 421px;
	width: 317px;
  }
  
  .bg .ellipse-7 {
	border: 5px solid;
	border-color: #ffffff;
	border-radius: 158.5px;
	height: 317px;
	left: 1012px;
	position: absolute;
	top: 421px;
	width: 317px;
  }
  
  .bg .text-wrapper-4 {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 35px;
	font-weight: 500;
	left: 18px;
	letter-spacing: 0;
	line-height: 40px;
	position: absolute;
	top: 614px;
	width: 849px;
  }
  
  .bg .overlap-9 {
	height: 473px;
	left: 660px;
	position: absolute;
	top: 2726px;
	width: 927px;
  }
  
  .bg .line-5 {
	height: 10px;
	left: 476px;
	position: absolute;
	top: 38px;
	width: 292px;
  }
  
  .bg .text-wrapper-5 {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 35px;
	font-weight: 500;
	left: 6px;
	letter-spacing: 0;
	line-height: 40px;
	position: absolute;
	top: 336px;
	width: 737px;
  }
  
  .bg .AMPLIFY-YOUR-REACH {
	color: #ffffff;
	font-family: "Archivo-ExtraBold", Helvetica;
	font-size: 85px;
	font-weight: 800;
	left: 0;
	letter-spacing: 0;
	line-height: 85px;
	position: absolute;
	top: 0;
	width: 927px;
  }
  
  .bg .ellipse-8 {
	height: 273px;
	left: 1623px;
	object-fit: cover;
	position: absolute;
	top: 2716px;
	width: 274px;
  }
  
  .bg .ellipse-9 {
	height: 272px;
	left: 1623px;
	object-fit: cover;
	position: absolute;
	top: 3016px;
	width: 274px;
  }
  
  .bg .overlap-10 {
	height: 844px;
	left: 691px;
	position: absolute;
	top: 3382px;
	width: 1480px;
  }
  
  .bg .overlap-11 {
	height: 844px;
	left: 0;
	position: absolute;
	top: 0;
	width: 1480px;
  }
  
  .bg .rectangle-5 {
	background-color: #cee74b;
	border-radius: 15px;
	height: 632px;
	left: 0;
	position: absolute;
	top: 212px;
	width: 471px;
  }
  
  .bg .text-wrapper-6 {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 35px;
	font-weight: 500;
	left: 628px;
	letter-spacing: 0;
	line-height: 40px;
	position: absolute;
	top: 212px;
	width: 672px;
  }
  
  .bg .rectangle-6 {
	background-color: #cee74b;
	border-radius: 15px;
	height: 117px;
	left: 455px;
	position: absolute;
	top: 0;
	width: 605px;
  }
  
  .bg .ARE-YOU-a-DIE-HARD {
	color: transparent;
	font-family: "Archivo-ExtraBold", Helvetica;
	font-size: 85px;
	font-weight: 800;
	left: 0;
	letter-spacing: 0;
	line-height: 85px;
	position: absolute;
	top: 21px;
	width: 1480px;
  }
  
  .bg .span {
	color: #ffffff;
  }
  
  .bg .text-wrapper-7 {
	color: #000000;
  }
  
  .bg .rectangle-7 {
	height: 602px;
	left: 40px;
	object-fit: cover;
	position: absolute;
	top: 212px;
	width: 488px;
  }
  
  .bg .overlap-12 {
	background-color: #f75918;
	border-radius: 10px;
	height: 57px;
	left: 628px;
	position: absolute;
	top: 756px;
	width: 223px;
  }
  
  .bg .text-wrapper-8 {
	color: #ffffff;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 18px;
	font-weight: 500;
	left: 53px;
	letter-spacing: 0;
	line-height: 55px;
	position: absolute;
	top: 3px;
	white-space: nowrap;
	width: 117px;
  }
  
  .bg .overlap-13 {
	border: 3px solid;
	border-color: #f75918;
	border-radius: 10px;
	height: 57px;
	left: 876px;
	position: absolute;
	top: 756px;
	width: 412px;
  }
  
  .bg .text-wrapper-9 {
	color: #f75918;
	font-family: "Archivo-Medium", Helvetica;
	font-size: 18px;
	font-weight: 500;
	left: 67px;
	letter-spacing: 0;
	line-height: 55px;
	position: absolute;
	top: -1px;
	white-space: nowrap;
	width: 271px;
  }
   */
